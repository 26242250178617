import * as React from "react"
import BaseLayout from "./../../../components/layout"
import Seo from "./../../../components/seo";
import { Link, graphql, useStaticQuery } from "gatsby";
import HorizontalRule from "./../../../components/horizontal-rule";
import TextBlock from "./../../../components/text-block";
import ArticleImage from "./../../../components/article-image";
import BreadcrumbTrail from "../../../components/breadcrumb-trail";


const StoriedScrollsThankYou = (props) => {
  const { site } = useStaticQuery(query);

  const aboutText = [
    "Thank you for subscribing to The Storied Scrolls! I'm the type of person who is reluctant to add mail to my already overflowing inbox, so I'm very grateful you added my newsletter to your list.",
    "Make sure to check your inbox and click through the link to verify your subscription.",
    "When you do, star the email, mark it as \"important,\" or add my address to your contacts so that I don't end up in your spam folder."
  ]

  let breadcrumbList = [
    [
      {
        title: 'Home',
        url: '/'
      },
      {
        title: 'Thank You!',
      }
    ]
  ]

  return (
    <BaseLayout>
      <Seo title="The Storied Scrolls Newsletter" pathname={props.location.pathname} breadcrumbList={breadcrumbList}></Seo>
      <div className="layout-base-w app-px-base pt-16 pb-16 md:pb-32">
        <BreadcrumbTrail breadcrumbTrail={breadcrumbList[0]}></BreadcrumbTrail>
        <div className="text-mint">
          <HorizontalRule ruleType="1"></HorizontalRule>
          <h2 className="text-subheader-1 mb-4">Thank You!</h2>
          <h1 className="text-header-1">The Storied Scrolls</h1>
        </div>

        <div className="mx-auto mt-32" style={{'maxWidth': '620px'}}>
          <ArticleImage classString="mb-12" src="https://media.zackthoutt.com/img/newsletter/newsletter-scroll@2x.png" alt="Zack profile picture"/>
          <TextBlock text={aboutText} textSize="large"></TextBlock>

          <div className="sm:flex justify-start items-center mt-12">
            <Link to="/writing/library/"><button className="btn btn-wide btn-gold w-full sm:w-auto">Start Reading →</button></Link>
          </div>

          <div className="text-mint my-40">
            <HorizontalRule ruleType="3"></HorizontalRule>
          </div>

        </div>
      </div>
    </BaseLayout>
  )

}

export default StoriedScrollsThankYou

const query = graphql`
  query StoriedScrollsThankYou {
    site {
      siteMetadata {
        ...CommonMetaData
        company
      }
    }
  }
`
